<template>

    <div v-if="isLoading">
        Loading...
    </div>
    <div v-else-if="error">
        Error: {{ error }}
    </div>
    <div v-else>
        <button class="block ">{{ category.name }}</button>
    </div>

</template>

<script>
// Import Firestore functions needed for document and collection references
import { doc, getDoc } from 'firebase/firestore';
import { db } from '@/firebase/firebaseConfig'; // Ensure this import correctly points to your Firebase configuration file

export default {
    name: "CategoryList",
    props: {
        categoryId: String,
    },
    data() {
        return {
            category: null,
            items: [],
            isLoading: true,
            error: null
        };
    },

    async mounted() {
        await this.fetchCategory();
    },
    methods: {
        async fetchCategory() {
            this.isLoading = true;
            const docRef = doc(db, "category", this.categoryId);
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                this.category = docSnap.data();
                this.isLoading = false;
                console.log("Category Sidebar:", this.category);
            } else {
                console.error("No such category found!");
                console.log("No such category!");
                this.error = "Category not found";
                return; // Stop further execution if no category is found
            }
        },
    }
}
</script>