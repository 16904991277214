<template>

  <div v-if="isLoading">
    Loading...
  </div>
  <div v-else-if="error">
    Error: {{ error }}
  </div>
  <div v-else>
    <div class="rounded-2xl border border-gray-700 bg-[#1D1C20] p-6 mb-6">
      <h4 class="text-2xl font-bold text-white">{{ category.name }}</h4>
      <p class="text-lg text-white">
        {{ category.description }}
      </p>
      <div class="mt-6 space-y-6">
        <div v-for="item in items" :key="item.id">
          <div class="flex items-start gap-4 rounded-2xl border-2 border-gray-700 p-4">
            <input type="checkbox"
              onchange="this.parentNode.classList.toggle('border-indigo-500'); this.parentNode.classList.toggle('border-gray-700')"
              class="peer size-8 rounded border-gray-300 text-indigo-600 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 focus:ring-offset-0" />
            <div class="text-white">
              <h4 class="text-xl font-semibold">{{ item.title }}</h4>
              <p class="text-base font-normal">{{ item.helptext }}</p>
              <div class="mt-4 flex flex-wrap items-center gap-2">
                <span v-for="link in item.links" :key="link.url">
                  <a :href="link.url" target="_blank" class="rounded-lg border border-gray-700 px-3 py-1 text-sm font-normal">{{
    link.title }}</a>
                </span>


              </div>
            </div>
          </div>
        </div>

      </div>
    </div>

  </div>

</template>

<script>
// Import Firestore functions needed for document and collection references
import { doc, getDoc, collection, getDocs } from 'firebase/firestore';
import { db } from '@/firebase/firebaseConfig'; // Ensure this import correctly points to your Firebase configuration file

export default {
  name: "ChecklistCategory",
  props: {
    categoryId: String,
  },
  data() {
    return {
      category: null,
      items: [],
      isLoading: true,
      error: null
    };
  },
  computed: {
    processedItems() {
      return this.items.map(item => ({
        ...item,
        links: item.links ? [item.links] : []  // Ensure links is always an array
      }));
    }
  },
  async mounted() {
    await this.fetchCategory();
    await this.fetchItems();
  },
  methods: {
    async fetchCategory() {
      this.isLoading = true;
      const docRef = doc(db, "category", this.categoryId);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        this.category = docSnap.data();
        console.log("Category Data:", this.category);
      } else {
        console.error("No such category found!");
        console.log("No such category!");
        this.error = "Category not found";
        return; // Stop further execution if no category is found
      }
    },
    async fetchItems() {
      const itemsRef = collection(db, "category", this.categoryId, "items");
      const snapshot = await getDocs(itemsRef);
      this.items = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      console.log("Items:", this.items);
      this.isLoading = false;
    }
  }
}
</script>
