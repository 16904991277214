<template>
  <div id="app">
    <div class="flex flex-col lg:flex-row items-start gap-6">
      <div class="flex-1">
        <checklist-category v-for="categoryId in categoryIds" :key="categoryId" :categoryId="categoryId" />

      </div>
      <div
        class="w-full sticky space-y-4 top-10 max-w-xs rounded-3xl bg-[#1D1C20] text-white border shadow-sm border-gray-700 p-6">
        
        <category-list v-for="categoryId in categoryIds" :key="categoryId" :categoryId="categoryId" />
      </div>

    </div>
  </div>
</template>

<script>
import ChecklistCategory from './components/ChecklistCategory.vue';
import CategoryList from './components/CategoryList.vue';

export default {
  name: 'App',
  components: {
    ChecklistCategory,
    CategoryList
  },
  data() {
    return {
      categoryIds: ['His7gGz3I2RYHULm5goJ', 's7R4qeo7UXpYXsIcU7VS'] 
    };
  }
}
</script>
